import { FormattedMessage } from 'gatsby-plugin-intl'
import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import { ProgramStatsEntry } from 'shared/types'
import StatCard from './StatCard'

interface StatsSectionCard {
  stats: ProgramStatsEntry
  isVisible: boolean
}

const StatsSectionCard = ({ stats, isVisible }: StatsSectionCard): JSX.Element => {
  return (
    <Container>
      <Row>
        <Col className="ml-auto mr-auto" md="8">
          <h2 className="title text-center">{stats.year}</h2>
        </Col>
      </Row>
      <Row>
        <StatCard isVisible={isVisible} textLabel="homepage_stats_mec" stat={stats.mec} icon="mec" />
        <StatCard isVisible={isVisible} textLabel="homepage_stats_ele" stat={stats.ele} icon="ele" />
        <StatCard isVisible={isVisible} textLabel="homepage_stats_gpa" stat={stats.gpa} icon="gpa" />
        <StatCard isVisible={isVisible} textLabel="homepage_stats_log" stat={stats.log} icon="log" />
        <StatCard isVisible={isVisible} textLabel="homepage_stats_ti" stat={stats.ti} icon="ti" />
        <StatCard isVisible={isVisible} textLabel="homepage_stats_ctn" stat={stats.ctn} icon="ctn" />
        <StatCard isVisible={isVisible} textLabel="homepage_stats_gol" stat={stats.gol} icon="gol" />
        <StatCard isVisible={isVisible} textLabel="homepage_stats_master" stat={stats.master} icon="master" />
        <StatCard isVisible={isVisible} textLabel="homepage_stats_doc" stat={stats.doc} icon="doc" />
      </Row>
      <Row>
        <Col className="ml-auto mr-auto" md="8">
          <div className="description text-center stat-disclaimer">
            <FormattedMessage id="homepage_stats_footer" />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default StatsSectionCard
