import React from 'react'
import { ToastContainer } from 'react-toastify'
import HomePage from 'components/HomePage'
import Header from 'components/Header'
import Footer from 'components/Footer'
import PageHelmet from 'components/PageHelmet'
import ScrollToTop from 'components/ScrollToTop'
import { ContentfulHomePageData, Location } from 'shared/types'
import { genericTitle, genericdescription, baseUrl, homeImage } from 'shared/constants'
import { graphql } from 'gatsby'
import { mapToHomePageFields, mapToStats, mapToGeneralPageFields } from 'services'

interface Home {
  location: Location
  data: ContentfulHomePageData
}

const Home = ({ location, data }: Home): JSX.Element => {
  const content = mapToGeneralPageFields(data.contentfulGeneralPageData)
  const homeContent = mapToHomePageFields(data.contentfulHomePage)
  const stats = mapToStats(data.allContentfulProgramStats)
  return (
    <>
      <PageHelmet title={genericTitle} description={genericdescription} url={baseUrl} image={homeImage} />
      <ScrollToTop location={location} />
      <ToastContainer />
      <Header location={location} contributionUrl={content.contributionUrl} merchandiseUrl={content.merchandiseUrl} />
      <HomePage content={homeContent} stats={stats} />
      <Footer />
    </>
  )
}

export const query = graphql`
  query HomePage($locale: String) {
    contentfulHomePage(node_locale: { eq: $locale }) {
      title
      subtitle
      contributionUrl
      missionTitle
      missionDescription {
        raw
      }
      firstObjectiveTitle
      firstObjectiveDescription {
        raw
      }
      secondObjectiveTitle
      secondObjectiveDescription {
        raw
      }
      faqTitle
      faqSection {
        reponse {
          raw
        }
        question
      }
      contentful_id
    }
    allContentfulProgramStats(filter: { node_locale: { eq: $locale } }, sort: { fields: year, order: DESC }) {
      nodes {
        ctn
        doc
        ele
        gol
        gpa
        log
        master
        mec
        ti
        year
      }
    }
    contentfulGeneralPageData {
      contentful_id
      contributionUrl
      merchandiseUrl
    }
  }
`
export default Home
