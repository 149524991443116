import { FormattedMessage } from 'gatsby-plugin-intl'
import React from 'react'
import { Col } from 'reactstrap'
import CountUp from 'react-countup'

interface StatCard {
  isVisible: boolean
  stat: number
  textLabel: string
  icon: string
}

const StatCard = ({ isVisible, stat, textLabel, icon }: StatCard): JSX.Element => {
  return (
    <Col sm="4" md="4">
      <div className="info">
        <div className={`stat-icon ${icon}`} />
        <div className="stat">{isVisible ? <CountUp end={stat} /> : 0}%</div>
        <div className="stat-description">
          <FormattedMessage id={textLabel} />
        </div>
      </div>
    </Col>
  )
}

export default StatCard
