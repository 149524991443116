/* eslint-disable max-len */
import React from 'react'

interface Chevron {
  className?: string
}

const Chevron = ({ className }: Chevron): JSX.Element => {
  return (
    <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" className={`icon chevron ${className}`}>
      <g>
        <g>
          <path
            d="M367.954,213.588L160.67,5.872c-7.804-7.819-20.467-7.831-28.284-0.029c-7.819,7.802-7.832,20.465-0.03,28.284
l207.299,207.731c7.798,7.798,7.798,20.486-0.015,28.299L132.356,477.873c-7.802,7.819-7.789,20.482,0.03,28.284
c3.903,3.896,9.016,5.843,14.127,5.843c5.125,0,10.25-1.958,14.157-5.873l207.269-207.701
C391.333,275.032,391.333,236.967,367.954,213.588z"
          />
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  )
}
export default Chevron
