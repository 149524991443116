import { Card, CardBody, Col, Collapse, ListGroupItem, Row } from 'reactstrap'
import React, { MouseEvent, ReactNode } from 'react'
import Chevron from '../Icons/Chevron'

interface FAQElement {
  question: string
  answer: ReactNode
}

const FAQElement = ({ question, answer }: FAQElement): JSX.Element => {
  const [collapses, setCollapses] = React.useState<number[]>([])
  const changeCollapse = (collapse: number) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((i) => i !== collapse))
    } else {
      setCollapses([...collapses, collapse])
    }
  }
  return (
    <>
      <ListGroupItem
        onClick={(e: MouseEvent) => {
          if ((e.target as Node).nodeName !== 'A') {
            e.preventDefault()
            changeCollapse(1)
          }
        }}
      >
        <Row className="respect-margin">
          <Col>
            <h3>{question}</h3>
          </Col>
          <Col md="auto">
            <Chevron className={collapses.includes(1) ? 'rotate' : ''} />
          </Col>
        </Row>
        <Collapse isOpen={collapses.includes(1)}>
          <Card className="faq-card">
            <CardBody>{answer}</CardBody>
          </Card>
        </Collapse>
      </ListGroupItem>
    </>
  )
}

export default FAQElement
