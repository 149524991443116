import React from 'react'
import { Container } from 'reactstrap'

interface ImageSection {
  title: string
  sousTitre: string
}

const ImageSection = ({ title, sousTitre }: ImageSection): JSX.Element => {
  return (
    <div id="main-header" className="page-header">
      <div className="filter" />
      <Container>
        <div className="motto text-center">
          <h1>{title}</h1>
          <h3>{sousTitre}</h3>
        </div>
      </Container>
    </div>
  )
}

export default ImageSection
