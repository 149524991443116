import React from 'react'
import { Card, Container, ListGroup } from 'reactstrap'
import { HomePageContentFAQSection } from 'shared/types'
import { JackInTheBox } from 'react-awesome-reveal'
import FAQElement from './FAQElement'

interface FAQSection {
  faqTitle: string
  faqSection: HomePageContentFAQSection
}

const FAQSection = ({ faqTitle, faqSection }: FAQSection): JSX.Element => {
  return (
    <div className="section text-section">
      <Container>
        <h2 className="title text-center">{faqTitle}</h2>
        <JackInTheBox triggerOnce>
          <Card>
            <ListGroup flush className="pointer-click">
              {faqSection.map((element: HomePageContentFAQSection) => (
                <FAQElement key={element.question} question={element.question} answer={element.reponse} />
              ))}
            </ListGroup>
          </Card>
        </JackInTheBox>
      </Container>
    </div>
  )
}

export default FAQSection
