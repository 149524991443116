import React from 'react'
import { HomePageContent, ProgramStatsEntry } from 'shared/types'
import ImageSection from './ImageSection'
import MissionSection from './MissionSection'
import StatsSection from './StatsSection'
import ContactSection from './ContactSection'
import FAQSection from './FAQSection'

interface HomePage {
  content: HomePageContent
  stats: ProgramStatsEntry[]
}

const HomePage = ({ content, stats }: HomePage): JSX.Element => {
  return (
    <>
      <ImageSection title={content.title} sousTitre={content.subtitle} />
      <div className="main">
        <MissionSection
          missionTitle={content.missionTitle}
          missionDescription={content.missionDescription}
          firstObjectiveTitle={content.firstObjectiveTitle}
          firstObjectiveDescription={content.firstObjectiveDescription}
          secondObjectiveTitle={content.secondObjectiveTitle}
          secondObjectiveDescription={content.secondObjectiveDescription}
        />
        <StatsSection stats={stats} />
        <FAQSection faqTitle={content.faqTitle} faqSection={content.faqSection} />
        <ContactSection />
      </div>
    </>
  )
}

export default HomePage
