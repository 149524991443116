/* eslint-disable max-len */
import React, { ReactNode, useState } from 'react'
import { Col, Container, Row, Card, CardTitle, CardBody } from 'reactstrap'
import { Slide } from 'react-awesome-reveal'
import { FaYoutube } from 'react-icons/fa'
import Woman from 'components/Icons/Woman'

interface MissionSection {
  missionTitle: string
  missionDescription: ReactNode
  firstObjectiveTitle: string
  firstObjectiveDescription: ReactNode
  secondObjectiveTitle: string
  secondObjectiveDescription: ReactNode
}

const MissionSection = ({
  missionTitle,
  missionDescription,
  firstObjectiveTitle,
  firstObjectiveDescription,
  secondObjectiveTitle,
  secondObjectiveDescription,
}: MissionSection): JSX.Element => {
  const [loadVideo, setLoadVideo] = useState(false)

  return (
    <div className="section text-center">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <h2 className="title">{missionTitle}</h2>
            <h5 className="description">{missionDescription}</h5>
            <br />
          </Col>
        </Row>
        <Row>
          <Col className="ml-auto mr-auto" md="6">
            <h3 className="title sub-section">{firstObjectiveTitle}</h3>
            <div className="description">{firstObjectiveDescription}</div>
          </Col>
          <Col className="ml-auto mr-auto" md="6">
            <Slide triggerOnce direction="right">
              <Card className="card-dark">
                <CardBody className="card-center-elements">
                  <Woman />
                  <CardTitle tag="h3">{secondObjectiveTitle}</CardTitle>
                  <div className="card-text">{secondObjectiveDescription}</div>
                </CardBody>
              </Card>
            </Slide>
          </Col>
        </Row>
        <br />
        <Slide triggerOnce direction="left">
          <Row>
            <Col className="ml-auto mr-auto video-player" md="12">
              {!loadVideo ? (
                // eslint-disable-next-line
                <div className="player" onClick={() => setLoadVideo(true)}>
                  <img
                    src="//img.youtube.com/vi/vhnFdVlORew/maxresdefault.jpg"
                    alt="https://www.youtube.com/watch?v=vhnFdVlORew&feature=emb_title"
                  />
                  <FaYoutube size="75px" color="#FF0000" className="play-button" />
                </div>
              ) : (
                <iframe
                  width="100%"
                  height="100%"
                  title="video"
                  src="https://www.youtube-nocookie.com/embed/vhnFdVlORew?autoplay=1"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              )}
            </Col>
          </Row>
        </Slide>
      </Container>
    </div>
  )
}

export default MissionSection
