import React, { useState } from 'react'
import Select, { ValueType } from 'react-select'
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap'
import { toast } from 'react-toastify'
import emailjs from 'emailjs-com'
import { ToastConfigs, FORM_TYPES } from 'shared/constants'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import fr from '../../intl/fr.json'
import en from '../../intl/en.json'

interface Option {
  value: string
  label: string
}
const FR_SUBJECTS = [
  {
    label: 'Devenir membre des INGénieuses',
    value: 'MEMBRE',
  },
  {
    label: 'Questions concernant le regroupement',
    value: 'REGROUPEMENT',
  },
  {
    label: 'Devenir un partenaire',
    value: 'PARTENAIRE',
  },
  {
    label: 'Organiser un événement avec les INGénieuses',
    value: 'EVENEMENT',
  },
  {
    label: 'Autre',
    value: 'AUTRE',
  },
]
const EN_SUBJECTS = [
  {
    label: 'Become a member of the INGénieuses',
    value: 'MEMBER',
  },
  {
    label: 'Questions about the club',
    value: 'QUESTION',
  },
  {
    label: 'Become a partner',
    value: 'PARTNER',
  },
  {
    label: 'Organize an event with the INGénieuses',
    value: 'EVENT',
  },
  {
    label: 'Other',
    value: 'Other',
  },
]
const ContactSection = (): JSX.Element => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState<Option | null>(useIntl().locale === 'en' ? EN_SUBJECTS[0] : FR_SUBJECTS[0])
  const [message, setMessage] = useState('')

  const intl = useIntl()

  const resetInputs = () => {
    setName('')
    setEmail('')
    // eslint-disable-next-line
    setSubject(useIntl().locale === 'en' ? EN_SUBJECTS[0] : FR_SUBJECTS[0])
    setMessage('')
  }

  const sendEmail = () => {
    if (name && email && subject && message) {
      emailjs
        .send(
          process.env.GATSBY_EMAIL_SERVICE_ID || '',
          process.env.GATSBY_CONTACT_FORM_TEMPLATE_ID || '',
          {
            nom: name,
            email,
            subject: `${subject.label} - ${FORM_TYPES.contactForm}`,
            message,
          },
          process.env.GATSBY_EMAIL_USER_ID || '',
        )
        .then(() => {
          toast.success(
            intl.locale === 'en' ? en.homepage_contact_form_sent : fr.homepage_contact_form_sent,
            ToastConfigs,
          )
          resetInputs()
        })
        .catch(() => {
          toast.error(
            intl.locale === 'en' ? en.homepage_contact_form_error : fr.homepage_contact_form_error,
            ToastConfigs,
          )
        })
    } else {
      toast.error(intl.locale === 'en' ? en.homepage_contact_form_fill : fr.homepage_contact_form_fill, ToastConfigs)
    }
  }

  return (
    <div className="section text-section">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="8">
            <h2 className="title text-center">
              <FormattedMessage id="homepage_contact_title" />
            </h2>
            <div className="contact-form">
              <Row>
                <Col md="6">
                  <Label>
                    <FormattedMessage id="homepage_contact_name" />
                  </Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={useIntl().locale === 'en' ? en.homepage_contact_name : fr.homepage_contact_name}
                      type="text"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value)
                      }}
                    />
                  </InputGroup>
                </Col>
                <Col md="6">
                  <Label>
                    <FormattedMessage id="homepage_contact_email" />
                  </Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={useIntl().locale === 'en' ? en.homepage_contact_email : fr.homepage_contact_email}
                      type="text"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value)
                      }}
                    />
                  </InputGroup>
                </Col>
              </Row>
              <FormGroup className=" col-md-16 ">
                <Label for="inputState">
                  <FormattedMessage id="homepage_contact_object" />
                </Label>
                <Select
                  classNamePrefix="contact"
                  name="sujet"
                  value={subject}
                  options={useIntl().locale === 'en' ? EN_SUBJECTS : FR_SUBJECTS}
                  placeholder={useIntl().locale === 'en' ? en.homepage_contact_object : fr.homepage_contact_object}
                  onChange={(value: ValueType<Option, false>) => {
                    setSubject(value as Option)
                  }}
                />
              </FormGroup>
              <Label>
                <FormattedMessage id="homepage_contact_message" />
              </Label>
              <Input
                placeholder={useIntl().locale === 'en' ? en.homepage_contact_message : fr.homepage_contact_message}
                type="textarea"
                rows="6"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value)
                }}
              />
              <Row>
                <Col className="ml-auto mr-auto center" md="4">
                  <Button className="btn-fill" color="primary" size="lg" onClick={sendEmail}>
                    <FormattedMessage id="homepage_contact_send" />
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ContactSection
