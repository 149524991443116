import { FormattedMessage } from 'gatsby-plugin-intl'
import React, { useState } from 'react'
import { Carousel, CarouselControl, CarouselIndicators, CarouselItem, Col, Container, Row } from 'reactstrap'
import { ProgramStatsEntry } from 'shared/types'
import { InView } from 'react-intersection-observer'
import StatsSectionCard from './StatsSectionCard'

interface StatsSection {
  stats: ProgramStatsEntry[]
}

const StatsSection = ({ stats }: StatsSection): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === Object.keys(stats).length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? Object.keys(stats).length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = (newIndex: React.SetStateAction<number>) => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const handleVisibilityChange = () => {
    setIsVisible(false)
    setIsVisible(true)
  }

  const slides = Object.keys(stats).map((item) => {
    return (
      <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={item}>
        <StatsSectionCard stats={stats[Number(item)]} isVisible={isVisible} />
      </CarouselItem>
    )
  })

  return (
    <div className="section text-center section-dark">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="8">
            <h2 className="title text-center">
              <FormattedMessage id="homepage_stats_title" />
            </h2>
          </Col>
        </Row>
      </Container>

      <InView triggerOnce onChange={handleVisibilityChange}>
        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
          <CarouselIndicators
            items={stats}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
            className="indicators-bot-50"
          />
          {slides}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
            className="carousel-stats"
          />
          <CarouselControl direction="next" directionText="Next" onClickHandler={next} className="carousel-stats" />
        </Carousel>
      </InView>
    </div>
  )
}

export default StatsSection
